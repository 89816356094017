

export default function Loading(){


    return(
        <div className="login-wrapper position-relative">
            <center >
                <h1>Loading...</h1>
            </center>
        </div>
    );
}