import './App.css';
import { Routes, Route } from "react-router-dom";
//import * as React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/header.css';
import Loading from './Loading';
import React from "react";



const Login = React.lazy(() => import("./LoginPage"));
const SignUpPage = React.lazy(() => import("./SignUp"));
const DoctorRegistration = React.lazy(() => import("./DoctorRegistration"));
const DoctormpanelmentRoute = React.lazy(() => import("./doctormpanelment"));
const Vendormpanelment = React.lazy(() => import("./Vendormpanelment"));
const Profile = React.lazy(() => import("./components/profile"));
const DoctorDashboard = React.lazy(() => import("./components/DoctorDashboard"));
const MyConsultations = React.lazy(() => import("./components/MyConsultations"));
const Reviews = React.lazy(() => import("./components/Reviews"));
const WaitingLobby = React.lazy(() => import("./components/WaitingLobby"));
const DrugTemplateList = React.lazy(() => import("./components/DrugTemplateList"));
const InvestigationTemplateList = React.lazy(() => import("./components/InvestigationTemplateList"));
const EMR = React.lazy(() => import("./components/EMR"));
const MyEMR = React.lazy(() => import("./components/MyEMR"));
const Teleconsultation = React.lazy(() => import("./components/Teleconsultation"));
const BasicInfo = React.lazy(() => import("./components/VendorService/ProfilePage"));
const VendorSignup = React.lazy(() => import("./VendorSignup"));
const VendorRegistration = React.lazy(() => import("./VendorRegistration"));
const VendorLogin = React.lazy(() => import("./VendorLogin"));
const VideoCall = React.lazy(() => import("./VideoCall"));
const DoctorChangePassword = React.lazy(() => import("./components/DoctorChangePassword"));
const VenderChangePassword = React.lazy(() => import("./components/VendorService/VenderChangePassword"));
const DoctorUnavailabilityGetData = React.lazy(() => import("./components/DoctorUnavailabilityGetData"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));

// const MyContext = createContext({
//   count: 0
// });

// function callAPI() {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => resolve(), 1000);
//   });
// }

// function useBackgroundService(someStateFromContext) {
//   const [backgroundService, setBackgroundService] = useState(null);

//   useEffect(() => {
//     const bgService = setInterval(() => {
//       if (someStateFromContext !== 0) {
//         return;
//       }
//       callAPI().then(() => {

//         console.log(
//           `ran background process with value from context: ${someStateFromContext}`
//         );
//       });
//     }, 2000);

//     function cleanup() {
//       clearInterval(bgService);
//     }

//     setBackgroundService(bgService);

//     return cleanup;
//   }, [someStateFromContext]);

//   return backgroundService;
// }


 function App() {
//   const myContext = useContext(MyContext);

//   useBackgroundService(myContext.count);
  return (
    <div>
      <Routes>
        <Route path="/"
          element={
            <React.Suspense fallback={<Loading />}>
              <Login />
            </React.Suspense>
          }
        />

        <Route path="/VendorLogin"
          element={
            <React.Suspense fallback={<Loading />}>
              <VendorLogin />
            </React.Suspense>
          }
        />

        <Route path="signUp"
          element={
            <React.Suspense fallback={<Loading />}>
              <SignUpPage />
            </React.Suspense>
          }
        />

<Route path="ForgotPassword"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgotPassword />
            </React.Suspense>
          }
        />


        <Route path="VideoCall"
          element={<React.Suspense fallback={<Loading />}> <VideoCall /> </React.Suspense>} >
        </Route>

        <Route path="VendorSignup"
          element={
            <React.Suspense fallback={<Loading />}>
              <VendorSignup />
            </React.Suspense>
          }
        />

        <Route path="DoctorRegistration"
          element={
            <React.Suspense fallback={<Loading />}>
              <DoctorRegistration />
            </React.Suspense>
          }
        />

        <Route path="VendorRegistration"
          element={
            <React.Suspense fallback={<Loading />}>
              <VendorRegistration />
            </React.Suspense>
          }
        />



        <Route path="/Vendormpanelment//*"
          element={
            <React.Suspense fallback={<Loading />}>
              <Vendormpanelment />
            </React.Suspense>} >
          <Route path="DashboardPage"
            element={
              <React.Suspense fallback={<Loading />}>
                <BasicInfo />
              </React.Suspense>} >
          </Route>
          <Route path="VenderChangePassword"
            element={<React.Suspense fallback={<Loading />}>
              <VenderChangePassword />
            </React.Suspense>} >
          </Route>



        </Route>

        <Route path="/DoctormpanelmentRoute//*" element={
          <React.Suspense fallback={<Loading />}>
            <DoctormpanelmentRoute />
          </React.Suspense>} >

          <Route path="profile" element={
            <React.Suspense fallback={<Loading />}><Profile /></React.Suspense>} ></Route>

          <Route path="DoctorDashboard"
            element={<React.Suspense fallback={<Loading />}> <DoctorDashboard /> </React.Suspense>} ></Route>

          <Route path="MyConsultations"
            element={<React.Suspense fallback={<Loading />}> <MyConsultations /> </React.Suspense>} ></Route>

          <Route path="Reviews"
            element={<React.Suspense fallback={<Loading />}> <Reviews /> </React.Suspense>} ></Route>

          <Route path="WaitingLobby"
            element={<React.Suspense fallback={<Loading />}> <WaitingLobby /> </React.Suspense>} ></Route>

          <Route path="DrugTemplateList"
            element={<React.Suspense fallback={<Loading />}> <DrugTemplateList /> </React.Suspense>} ></Route>

          <Route path="InvestigationTemplateList"
            element={<React.Suspense fallback={<Loading />}> <InvestigationTemplateList /> </React.Suspense>} >
          </Route>

          <Route path="EMR"
            element={<React.Suspense fallback={<Loading />}> <EMR /> </React.Suspense>} >
          </Route>

          <Route path="MyEMR"
            element={<React.Suspense fallback={<Loading />}> <MyEMR /> </React.Suspense>} >
          </Route>


          <Route path="Teleconsultation"
            element={<React.Suspense fallback={<Loading />}> <Teleconsultation /> </React.Suspense>} >
          </Route>
          <Route path="DoctorChangePassword"
            element={<React.Suspense fallback={<Loading />}> <DoctorChangePassword /> </React.Suspense>} ></Route>

          <Route path="DoctorUnavailabilityGetData"
            element={<React.Suspense fallback={<Loading />}><DoctorUnavailabilityGetData /></React.Suspense>} >
          </Route>

        </Route>




        {/* <Route path="/VendorServiceRoute//*" 
                element={
                  <React.Suspense fallback={<div className="login-wrapper position-relative"></div>}>
                    <VendorServiceRoute/>
                  </React.Suspense>
                  } 
              >
                <Route path="DashboardPage" 
                  element={
                    <React.Suspense fallback={<div className="login-wrapper position-relative"></div>}>
                      <BasicInfo/>
                    </React.Suspense>
                } 
                ></Route>
                  
              </Route> */}
      </Routes>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
